]<!-- =========================================================================================
    File Name: Faq.vue
    Description: FAQ Page
========================================================================================== -->

<template>
  <div id="faq-page">
    <!-- JUMBOTRON -->
    <div class="faq-jumbotron">
      <div class="faq-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
        <h1 class="mb-1 text-white">Have Any Questions? [WORK IN PROGRESS]</h1>
        <p class="text-white">Bonbon sesame snaps lemon drops marshmallow ice cream carrot cake croissant wafer.</p>
        <vs-input
          placeholder="Search"
          v-model="faqSearchQuery"
          icon-pack="feather"
          icon="icon-search"
          size="large"
          class="w-full mt-6"
          icon-no-border
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-2/5 lg:w-1/4 rounded-lg">
        <vx-card>
          <h4>Table of Content</h4>
          <ul class="faq-topics mt-4">
            <li
              v-for="category in categories"
              :key="category.id"
              class="p-2 font-medium flex items-center"
              @click="faqFilter = category.id"
            >
              <div class="h-3 w-3 rounded-full mr-2" :class="'bg-' + category.color"></div>
              <span class="cursor-pointer">{{ category.name }}</span>
            </li>
          </ul>

          <br /><br />

          <!-- <h4>Supporters</h4>
                    <ul class="faq-supporters mt-4">
                        <li v-for="supporter in supporters" :key="supporter.id" class="mt-4">
                            <div class="flex items-center">
                                <vs-avatar class="mr-3" :src="supporter.img" size="35px" />
                                <div class="leading-tight">
                                    <p class="font-semibold">{{ supporter.name }}</p>
                                    <small>{{ supporter.profession }}</small>
                                </div>
                            </div>
                        </li>
                    </ul> -->
        </vx-card>
      </div>

      <!-- FAQ COL -->
      <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0">
        <vs-collapse accordion type="margin" class="p-0">
          <vs-collapse-item
            v-for="(que, index) in filteredFaq"
            class="faq-bg rounded-lg"
            :class="{ 'mt-0': !index }"
            :key="que.id"
          >
            <div slot="header">
              <h5>{{ que.question }}</h5>
            </div>
            <p>{{ que.ans }}</p>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqSearchQuery: '',
      faqFilter: 1,
      categories: [
        {
          id: 1,
          name: 'All',
          color: 'grey',
        },
        {
          id: 2,
          name: 'General',
          color: 'primary',
        },
      ],
      faqs: [
        {
          id: 1,
          categoryId: 2,
          question: 'Why Hoverlay Places?',
          ans:
            'Our #1 goal at Hoverlay is to make the spatial web accessible and useful for people who want to communicate their message in the most impactful way possible. To do that, we’ve created an integrated platform to handle the complexities of augmented reality on your behalf.',
        },
        {
          id: 2,
          categoryId: 2,
          question: 'What are Hoverlay objects (a.k.a hobjects)?',
          ans:
            'This is our name for a piece of content that you can publish in the world, and provide extra Abilities. It might be a video avatar created from your own green screen video, telling the story of a monument, or a historical photo you position in front of a building. It might also be an actionable link that enables users to tweet, send you an email, take a poll or donate.',
        },
        {
          id: 3,
          categoryId: 2,
          question: 'What is a channel?',
          ans:
            'Every Hoverlay user has one or more Channels to organize their Hobjects. People tune to a Channel to access your Hobjects any place, any time. Channels are geo-located based on the content you place. So users can see what channels have content around them. Each channel has a unique URL, which you can use on signage, QRCode or on a page to take users straight to your content.',
        },
        {
          id: 4,
          categoryId: 2,
          question: 'What spatial arrangement can i use to create my experience?',
          ans:
            'You can place content in front of or around a user, on a surface (ground, table), or on an image (a mural, a facade, etc.).',
        },
        {
          id: 5,
          categoryId: 2,
          question: 'What is a range?',
          ans:
            'When you publish content, you specify a zone where a user need to be to experience that content. This is done by selecting a location on a map and a range.',
        },
        {
          id: 6,
          categoryId: 2,
          question: 'Can i publish objects that can be accessed anywhere?',
          ans:
            'Yes. Any placement can have an infinite range. In that case, users will see your content anywhere they are',
        },
      ],
    }
  },
  computed: {
    filteredFaq() {
      return this.faqs.filter(faq => {
        if (this.faqFilter === 1)
          return (
            faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) ||
            faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase())
          )
        else if (this.faqFilter === 2)
          return (
            faq.categoryId === 2 &&
            (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) ||
              faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()))
          )
        else if (this.faqFilter === 3)
          return (
            faq.categoryId === 3 &&
            (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) ||
              faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()))
          )
        else if (this.faqFilter === 4)
          return (
            faq.categoryId === 4 &&
            (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) ||
              faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()))
          )
        else if (this.faqFilter === 5)
          return (
            faq.categoryId === 5 &&
            (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) ||
              faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()))
          )
      })
    },
  },
  methods: {},
  components: {},
}
</script>

<style lang="scss">
#faq-page {
  .faq-jumbotron-content {
    background-image: url('../../../../assets/images/pages/faq.jpg');
    background-size: cover;
  }

  .faq-bg {
    background-color: #fff;
  }
}
</style>
